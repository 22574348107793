import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IdentityItems, IdentityVerificationResponse, TokenType } from 'app/core/types';
import identityVerification from '../utils/endpoints/identity-vertification.endpoint';

@Injectable({
	providedIn: 'root',
})
export class IdentityVerificationService {
	#httpClient = inject(HttpClient);

	getItems(type: number | null = null): Observable<IdentityItems[]> {
		const url = type ? `/kyc/kycs/items?Type=${type}` : '/kyc/kycs/items';
		return this.#httpClient.get<IdentityItems[]>(url);
	}

	uploadFile = (formData: FormData): Observable<unknown> =>
		this.#httpClient.post<unknown>(identityVerification.uploadFile, formData);

	submit = (userKycItems: unknown): Observable<unknown> =>
		this.#httpClient.post<unknown>(identityVerification.submit, { userKycItems });

	getDetail = (): Observable<IdentityVerificationResponse> =>
		this.#httpClient.get<IdentityVerificationResponse>(identityVerification.getDetail);

	putEscrow = (data: unknown): Observable<unknown> => this.#httpClient.put(identityVerification.putEscrow, data);

	getToken = (code: string): Observable<TokenType> =>
		this.#httpClient.post<TokenType>(identityVerification.getToken, code);
}
