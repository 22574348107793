export enum IdentityType {
	Personal = 1,
	Business = 2,
}

export enum UserKycStatus {
	None = 0,
	Review = 1,
	Rejected = 2,
	Verified = 3,
}

export enum utilityBills {
	Gas = 'Gas',
	Water = 'Water',
	Electricity = 'Electricity',
	BankStatement = 'Bank Statement',
	LicenseOfcertificate = 'License or certificate',
}

export enum  businessCurency {
	USD = 'USD',
	VND = 'VND',
	EUR = 'EUR',
	AUD = 'AUD',
}