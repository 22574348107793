export enum UserKycStatus {
	None = 0,
	Review = 1,
	Rejected = 2,
	Verified = 3,
}

export enum EscrowType {
	None,
	Banking,
	BankCheck,
	ReceiveUSDT,
	ReceiveVPC,
}

export const STEP = [
	{
		title: 'kyc-form.step1.title',
		key: ['FirstName', 'Middle', 'LastName','PhonePersonal','EmailPersonal', 'DateOfBirth', 'Address', 'City', 'State', 'ZipCode', 'Country'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step2.title',
		key: ['DocumentType', 'IdentityCardFront', ],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step3.title',
		key: [
			'BusinessName',
			'BusinessType',
			'BusinessWebsite',
			'BusinessAddress',
			'BusinessCity',
			'BusinessState',
			'BusinessZipCode',
			'BusinessCountry',
			'PhoneNumber',
			'Email',
			'UtilityBillType',
			'UtilityBillFront'
		],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'location',
		key: ['Longitude', 'Latitude'],
		items: [],
		hasItemInvalid: false,
	},
];

export const DIAL_CODE = [
	{ code: '+1', country: 'United States' },
	{ code: '+84', country: 'Vietnam' },
	{ code: '+93', country: 'Afghanistan' },
	{ code: '+355', country: 'Albania' },
	{ code: '+213', country: 'Algeria' },
	{ code: '+1-684', country: 'American Samoa' },
	{ code: '+376', country: 'Andorra' },
	{ code: '+244', country: 'Angola' },
	{ code: '+1-264', country: 'Anguilla' },
	{ code: '+672', country: 'Antarctica' },
	{ code: '+1-268', country: 'Antigua and Barbuda' },
	{ code: '+54', country: 'Argentina' },
	{ code: '+374', country: 'Armenia' },
	{ code: '+297', country: 'Aruba' },
	{ code: '+61', country: 'Australia' },
	{ code: '+43', country: 'Austria' },
	{ code: '+994', country: 'Azerbaijan' },
	{ code: '+1-242', country: 'Bahamas' },
	{ code: '+973', country: 'Bahrain' },
	{ code: '+880', country: 'Bangladesh' },
	{ code: '+1-246', country: 'Barbados' },
	{ code: '+375', country: 'Belarus' },
	{ code: '+32', country: 'Belgium' },
	{ code: '+501', country: 'Belize' },
	{ code: '+229', country: 'Benin' },
	{ code: '+1-441', country: 'Bermuda' },
	{ code: '+975', country: 'Bhutan' },
	{ code: '+591', country: 'Bolivia' },
	{ code: '+387', country: 'Bosnia and Herzegovina' },
	{ code: '+267', country: 'Botswana' },
	{ code: '+55', country: 'Brazil' },
	{ code: '+246', country: 'British Indian Ocean Territory' },
	{ code: '+1-284', country: 'British Virgin Islands' },
	{ code: '+673', country: 'Brunei' },
	{ code: '+359', country: 'Bulgaria' },
	{ code: '+226', country: 'Burkina Faso' },
	{ code: '+95', country: 'Myanmar' },
	{ code: '+257', country: 'Burundi' },
	{ code: '+855', country: 'Cambodia' },
	{ code: '+237', country: 'Cameroon' },
	{ code: '+238', country: 'Cape Verde' },
	{ code: '+1-345', country: 'Cayman Islands' },
	{ code: '+236', country: 'Central African Republic' },
	{ code: '+235', country: 'Chad' },
	{ code: '+56', country: 'Chile' },
	{ code: '+86', country: 'China' },
	{ code: '+61', country: 'Christmas Island' },
	{ code: '+61', country: 'Cocos Islands' },
	{ code: '+57', country: 'Colombia' },
	{ code: '+269', country: 'Comoros' },
	{ code: '+242', country: 'Republic of the Congo' },
	{ code: '+243', country: 'Democratic Republic of the Congo' },
	{ code: '+682', country: 'Cook Islands' },
	{ code: '+506', country: 'Costa Rica' },
	{ code: '+385', country: 'Croatia' },
	{ code: '+53', country: 'Cuba' },
	{ code: '+599', country: 'Curacao' },
	{ code: '+357', country: 'Cyprus' },
	{ code: '+420', country: 'Czech Republic' },
	{ code: '+45', country: 'Denmark' },
	{ code: '+253', country: 'Djibouti' },
	{ code: '+1-767', country: 'Dominica' },
	{ code: '+670', country: 'East Timor' },
	{ code: '+593', country: 'Ecuador' },
	{ code: '+20', country: 'Egypt' },
	{ code: '+503', country: 'El Salvador' },
	{ code: '+240', country: 'Equatorial Guinea' },
	{ code: '+291', country: 'Eritrea' },
	{ code: '+372', country: 'Estonia' },
	{ code: '+251', country: 'Ethiopia' },
	{ code: '+500', country: 'Falkland Islands' },
	{ code: '+298', country: 'Faroe Islands' },
	{ code: '+679', country: 'Fiji' },
	{ code: '+358', country: 'Finland' },
	{ code: '+33', country: 'France' },
	{ code: '+689', country: 'French Polynesia' },
	{ code: '+241', country: 'Gabon' },
	{ code: '+220', country: 'Gambia' },
	{ code: '+995', country: 'Georgia' },
	{ code: '+49', country: 'Germany' },
	{ code: '+233', country: 'Ghana' },
	{ code: '+350', country: 'Gibraltar' },
	{ code: '+30', country: 'Greece' },
	{ code: '+299', country: 'Greenland' },
	{ code: '+1-473', country: 'Grenada' },
	{ code: '+1-671', country: 'Guam' },
	{ code: '+502', country: 'Guatemala' },
	{ code: '+44-1481', country: 'Guernsey' },
	{ code: '+224', country: 'Guinea' },
	{ code: '+245', country: 'Guinea-Bissau' },
	{ code: '+592', country: 'Guyana' },
	{ code: '+509', country: 'Haiti' },
	{ code: '+504', country: 'Honduras' },
	{ code: '+852', country: 'Hong Kong' },
	{ code: '+36', country: 'Hungary' },
	{ code: '+354', country: 'Iceland' },
	{ code: '+91', country: 'India' },
	{ code: '+62', country: 'Indonesia' },
	{ code: '+98', country: 'Iran' },
	{ code: '+964', country: 'Iraq' },
	{ code: '+353', country: 'Ireland' },
	{ code: '+44-1624', country: 'Isle of Man' },
	{ code: '+972', country: 'Israel' },
	{ code: '+39', country: 'Italy' },
	{ code: '+225', country: 'Ivory Coast' },
	{ code: '+1-876', country: 'Jamaica' },
	{ code: '+81', country: 'Japan' },
	{ code: '+44-1534', country: 'Jersey' },
	{ code: '+962', country: 'Jordan' },
	{ code: '+7', country: 'Kazakhstan' },
	{ code: '+254', country: 'Kenya' },
	{ code: '+686', country: 'Kiribati' },
	{ code: '+383', country: 'Kosovo' },
	{ code: '+965', country: 'Kuwait' },
	{ code: '+996', country: 'Kyrgyzstan' },
	{ code: '+856', country: 'Laos' },
	{ code: '+371', country: 'Latvia' },
	{ code: '+961', country: 'Lebanon' },
	{ code: '+266', country: 'Lesotho' },
	{ code: '+231', country: 'Liberia' },
	{ code: '+218', country: 'Libya' },
	{ code: '+423', country: 'Liechtenstein' },
	{ code: '+370', country: 'Lithuania' },
	{ code: '+352', country: 'Luxembourg' },
	{ code: '+853', country: 'Macau' },
	{ code: '+389', country: 'Macedonia' },
	{ code: '+261', country: 'Madagascar' },
	{ code: '+265', country: 'Malawi' },
	{ code: '+60', country: 'Malaysia' },
	{ code: '+960', country: 'Maldives' },
	{ code: '+223', country: 'Mali' },
	{ code: '+356', country: 'Malta' },
	{ code: '+692', country: 'Marshall Islands' },
	{ code: '+222', country: 'Mauritania' },
	{ code: '+230', country: 'Mauritius' },
	{ code: '+262', country: 'Mayotte' },
	{ code: '+52', country: 'Mexico' },
	{ code: '+691', country: 'Micronesia' },
	{ code: '+373', country: 'Moldova' },
	{ code: '+377', country: 'Monaco' },
	{ code: '+976', country: 'Mongolia' },
	{ code: '+382', country: 'Montenegro' },
	{ code: '+1-664', country: 'Montserrat' },
	{ code: '+212', country: 'Morocco' },
	{ code: '+258', country: 'Mozambique' },
	{ code: '+264', country: 'Namibia' },
	{ code: '+674', country: 'Nauru' },
	{ code: '+977', country: 'Nepal' },
	{ code: '+31', country: 'Netherlands' },
	{ code: '+599', country: 'Netherlands Antilles' },
	{ code: '+687', country: 'New Caledonia' },
	{ code: '+64', country: 'New Zealand' },
	{ code: '+505', country: 'Nicaragua' },
	{ code: '+227', country: 'Niger' },
	{ code: '+234', country: 'Nigeria' },
	{ code: '+683', country: 'Niue' },
	{ code: '+1-670', country: 'Northern Mariana Islands' },
	{ code: '+850', country: 'North Korea' },
	{ code: '+47', country: 'Norway' },
	{ code: '+968', country: 'Oman' },
	{ code: '+92', country: 'Pakistan' },
	{ code: '+680', country: 'Palau' },
	{ code: '+970', country: 'Palestine' },
	{ code: '+507', country: 'Panama' },
	{ code: '+675', country: 'Papua New Guinea' },
	{ code: '+595', country: 'Paraguay' },
	{ code: '+51', country: 'Peru' },
	{ code: '+63', country: 'Philippines' },
	{ code: '+64', country: 'Pitcairn' },
	{ code: '+48', country: 'Poland' },
	{ code: '+351', country: 'Portugal' },
	{ code: '+974', country: 'Qatar' },
	{ code: '+262', country: 'Reunion' },
	{ code: '+40', country: 'Romania' },
	{ code: '+7', country: 'Russia' },
	{ code: '+250', country: 'Rwanda' },
	{ code: '+590', country: 'Saint Barthelemy' },
	{ code: '+685', country: 'Samoa' },
	{ code: '+378', country: 'San Marino' },
	{ code: '+239', country: 'Sao Tome and Principe' },
	{ code: '+966', country: 'Saudi Arabia' },
	{ code: '+221', country: 'Senegal' },
	{ code: '+381', country: 'Serbia' },
	{ code: '+248', country: 'Seychelles' },
	{ code: '+232', country: 'Sierra Leone' },
	{ code: '+65', country: 'Singapore' },
	{ code: '+1-721', country: 'Sint Maarten' },
	{ code: '+421', country: 'Slovakia' },
	{ code: '+386', country: 'Slovenia' },
	{ code: '+677', country: 'Solomon Islands' },
	{ code: '+252', country: 'Somalia' },
	{ code: '+27', country: 'South Africa' },
	{ code: '+82', country: 'South Korea' },
	{ code: '+211', country: 'South Sudan' },
	{ code: '+34', country: 'Spain' },
	{ code: '+94', country: 'Sri Lanka' },
	{ code: '+290', country: 'Saint Helena' },
	{ code: '+1-869', country: 'Saint Kitts and Nevis' },
	{ code: '+1-758', country: 'Saint Lucia' },
	{ code: '+590', country: 'Saint Martin' },
	{ code: '+508', country: 'Saint Pierre and Miquelon' },
	{ code: '+1-784', country: 'Saint Vincent and the Grenadines' },
	{ code: '+249', country: 'Sudan' },
	{ code: '+597', country: 'Suriname' },
	{ code: '+47', country: 'Svalbard and Jan Mayen' },
	{ code: '+268', country: 'Swaziland' },
	{ code: '+46', country: 'Sweden' },
	{ code: '+41', country: 'Switzerland' },
	{ code: '+963', country: 'Syria' },
	{ code: '+886', country: 'Taiwan' },
	{ code: '+992', country: 'Tajikistan' },
	{ code: '+255', country: 'Tanzania' },
	{ code: '+66', country: 'Thailand' },
	{ code: '+228', country: 'Togo' },
	{ code: '+690', country: 'Tokelau' },
	{ code: '+676', country: 'Tonga' },
	{ code: '+1-868', country: 'Trinidad and Tobago' },
	{ code: '+216', country: 'Tunisia' },
	{ code: '+90', country: 'Turkey' },
	{ code: '+993', country: 'Turkmenistan' },
	{ code: '+1-649', country: 'Turks and Caicos Islands' },
	{ code: '+688', country: 'Tuvalu' },
	{ code: '+971', country: 'United Arab Emirates' },
	{ code: '+256', country: 'Uganda' },
	{ code: '+44', country: 'United Kingdom' },
	{ code: '+380', country: 'Ukraine' },
	{ code: '+598', country: 'Uruguay' },
	{ code: '+998', country: 'Uzbekistan' },
	{ code: '+678', country: 'Vanuatu' },
	{ code: '+379', country: 'Vatican' },
	{ code: '+58', country: 'Venezuela' },
	{ code: '+1-340', country: 'U.S. Virgin Islands' },
	{ code: '+681', country: 'Wallis and Futuna' },
	{ code: '+212', country: 'Western Sahara' },
	{ code: '+967', country: 'Yemen' },
	{ code: '+260', country: 'Zambia' },
	{ code: '+263', country: 'Zimbabwe' },
];
export const BUSINNES_TYPE = [
	{ name: "Proprietary limited company" },
	{ name: "Public company" },
	{ name: "Incorporated association" },
	{ name: "Unincorporated association" },
	{ name: "Registered cooperative" },
	{ name: "Partnership" },
	{ name: "Government body" },
	{ name: "Trust" },
];
export const SERVICE_BUSINESS = [
	{ name: "General Contractors-Residential and Commercial" },
	{ name: "Heating, Plumbing, and Air Conditioning Contractors" },
	{ name: "Electrical Contractors" },
	{ name: "Masonry, Stonework, Tile Setting, Plastering and Insulation Contractors" },
	{ name: "Carpentry Contractors" },
	{ name: "Roofing, Siding, and Sheet Metal Work Contractors" },
	{ name: "Concrete Work Contractors" },
	{ name: "Special Trade Contractors" },
	{ name: "Miscellaneous Publishing and Printing" },
	{ name: "Typesetting, Plate Making and Related Services" },
	{ name: "Specialty Cleaning, Polishing and Sanitation Preparations" },
	{ name: "Affiliated Auto Rental" },
	{ name: "Taxicabs and Limousines" },
	{ name: "Bus Lines" },
	{ name: "Motor Freight Carriers and Trucking-Local and Long Distance, Moving and Storage Companies, and Local Delivery Services" },
	{ name: "Courier Services-Air and Ground, and Freight Forwarders" },
	{ name: "Public Warehousing and Storage-Farm Products, Refrigerated Goods, Household Goods, and Storage" },
	{ name: "Steamship and Cruise Lines" },
	{ name: "Boat Rentals and Leasing" },
	{ name: "Marinas, Marine Service, and Supplies" },
	{ name: "Travel Agencies and Tour Operators" },
	{ name: "Transportation Services" },
	{ name: "Telecommunication Equipment and Phone Sales" },
	{ name: "Telecommunication Services, including Local and Long Distance Calls, Credit Card Calls, Calls Through Use of Magnetic-Stripe-Reading Telephones, and Fax Services" },
	{ name: "Computer Network/Information Services" },
	{ name: "Telegraph Services" },
	{ name: "Wire Transfer Money Orders (WTMOs)" },
	{ name: "Cable, Satellite and Other Pay Television/Radio/Streaming Services" },
	{ name: "Utilities-Electric, Petrol, Water, and Sanitary" },
	{ name: "Motor Vehicle Supplies and New Parts" },
	{ name: "Office and Commercial Furniture" },
	{ name: "Construction Materials" },
	{ name: "Photographic, Photocopy, Microfilm Equipment and Supplies" },
	{ name: "Computers and Computer Peripheral Equipment and Software" },
	{ name: "Commercial Equipment" },
	{ name: "Medical, Dental, Ophthalmic and Hospital Equipment and Supplies" },
	{ name: "Metal Service Centres and Offices" },
	{ name: "Electrical Parts and Equipment" },
	{ name: "Hardware, Equipment and Supplies" },
	{ name: "Plumbing and Heating Equipment and Supplies" },
	{ name: "Industrial Supplies" },
	{ name: "Precious Stones and Metals, Watches and Jewellery" },
	{ name: "Durable Goods" },
	{ name: "Piece Goods, Notions, and Other Dry Goods" },
	{ name: "Men's, Women's, and Children's Uniforms and Commercial Clothing" },
	{ name: "Commercial Footwear" },
	{ name: "Chemicals and Allied Products" },
	{ name: "Paints, Varnishes and Supplies" },
	{ name: "Nondurable Goods" },
	{ name: "Home Supply Warehouse Stores" },
	{ name: "Lumber and Building Materials Stores" },
	{ name: "Glass, Paint, and Wallpaper Stores" },
	{ name: "Hardware Stores" },
	{ name: "Nurseries and Lawn and Garden Supply Stores" },
	{ name: "Marketplaces" },
	{ name: "Mobile Home Dealers" },
	{ name: "Discount Stores" },
	{ name: "Department Stores" },
	{ name: "Variety Stores" },
	{ name: "Miscellaneous General Merchandise" },
	{ name: "Freezer and Locker Meat Provisioners" },
	{ name: "Candy, Nut, and Confectionery Stores" },
	{ name: "Dairy Products Stores" },
	{ name: "Bakeries" },
	{ name: "Miscellaneous Food Stores-Convenience Stores and Specialty Markets" },
	{ name: "Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing" },
	{ name: "Car and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing" },
	{ name: "Automotive Tyre Stores" },
	{ name: "Automotive Parts and Accessories Stores" },
	{ name: "Boat Dealers" },
	{ name: "Electric Vehicle Charging" },
	{ name: "Camper, Recreational and Utility Trailer Dealers" },
	{ name: "Motorcycle Shops and Dealers" },
	{ name: "Motor Homes Dealers" },
	{ name: "Snowmobile Dealers" },
	{ name: "Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers" },
	{ name: "Men's and Boys' Clothing and Accessories Stores" },
	{ name: "Women's Ready-To-Wear Stores" },
	{ name: "Women's Accessory and Specialty Shops" },
	{ name: "Children's and Infants' Wear Stores" },
	{ name: "Family Clothing Stores" },
	{ name: "Sports and Riding Clothing Stores" },
	{ name: "Shoe Stores" },
	{ name: "Men's and Women's Clothing Stores" },
	{ name: "Tailors, Seamstresses, Mending, and Alterations" },
	{ name: "Wig and Toupee Stores" },
	{ name: "Miscellaneous Clothing and Accessory Shops" },
	{ name: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances" },
	{ name: "Floor Covering Stores" },
	{ name: "Drapery, Window Covering, and Upholstery Stores" },
	{ name: "Fireplace, Fireplace Screens and Accessories Stores" },
	{ name: "Miscellaneous Home Furnishing Specialty Stores" },
	{ name: "Household Appliance Stores" },
	{ name: "Electronics Stores" },
	{ name: "Music Stores-Musical Instruments, Pianos, and Sheet Music" },
	{ name: "Computer Software Stores" },
	{ name: "Record Stores" },
	{ name: "Caterers" },
	{ name: "Eating Places and Restaurants" },
	{ name: "Fast Food Restaurants" },
	{ name: "Digital Goods Media-Books, Movies, Music" },
	{ name: "Digital Goods-Games" },
	{ name: "Digital Goods-Applications (Excludes Games)" },
	{ name: "Drug Stores and Pharmacies" },
	{ name: "Package Stores-Beer, Wine, and Liquor" },
	{ name: "Used Merchandise and Secondhand Stores" },
	{ name: "Antique Shops-Sales, Repairs, and Restoration Services" },
	{ name: "Wrecking and Salvage Yards" },
	{ name: "Bicycle Shops-Sales and Service" },
	{ name: "Sporting Goods Stores" },
	{ name: "Bookshops" },
	{ name: "Stationery Stores, Office and School Supply Stores" },
	{ name: "Hobby, Toy, and Game Shops" },
	{ name: "Camera and Photographic Supply Stores" },
	{ name: "Gift, Card, Novelty and Souvenir Shops" },
	{ name: "Luggage and Leather Goods Stores" },
	{ name: "Sewing, Needlework, Fabric and Piece Goods Stores" },
	{ name: "Glassware/Crystal Stores" },
	{ name: "Direct Marketing-Insurance Services" },
	{ name: "Direct Marketing-Inbound Teleservices Merchant" },
	{ name: "Direct Marketing-Other Direct Marketers" },
	{ name: "Artist's Supply and Craft Shops" },
	{ name: "Art Dealers and Galleries" },
	{ name: "Stamp and Coin Stores" },
	{ name: "Religious Goods Stores" },
	{ name: "Hearing Aids-Sales, Service, and Supply" },
	{ name: "Orthopaedic Goods-Prosthetic Devices" },
	{ name: "Cosmetic Stores" },
	{ name: "Typewriter Stores-Sales, Rentals, and Service" },
	{ name: "Florists" },
	{ name: "Cigar Stores and Stands" },
	{ name: "News Dealers and Newsstands" },
	{ name: "Pet Shops, Pet Foods and Supplies Stores" },
	{ name: "Swimming Pools-Sales and Service" },
	{ name: "Electric Razor Stores-Sales and Service" },
	{ name: "Tent and Awning Shops" },
	{ name: "Miscellaneous and Specialty Retail Shops" },
	{ name: "Financial Institutions-Merchandise and Services" },
	{ name: "Non-Financial Institutions-Foreign Currency, Money Orders (Not Wire Transfer), and Travellers Cheques" },
	{ name: "Security Brokers/Dealers" },
	{ name: "Insurance Sales, Underwriting, and Premiums" },
	{ name: "Real Estate Agents and Managers-Rentals" },
	{ name: "Lodging-Hotels, Motels, Resorts, Central Reservation Services" },
	{ name: "Timeshares" },
	{ name: "Sporting and Recreational Camps" },
	{ name: "Trailer Parks and Camping Grounds" },
	{ name: "Laundromat, Cleaning, and Garment Services" },
	{ name: "Laundries-Family and Commercial" },
	{ name: "Dry Cleaners" },
	{ name: "Carpet and Upholstery Cleaning" },
	{ name: "Photographic Studios" },
	{ name: "Beauty and Barber Shops" },
	{ name: "Shoe Repair Shops, Shoe Shine Parlours, and Hat Cleaning Shops" },
	{ name: "Dating Services" },
	{ name: "Counselling Services-Debt, Marriage, and Personal" },
	{ name: "Buying and Shopping Services and Clubs" },
	{ name: "Clothing Rental-Costumes, Uniforms, Formal Wear" },
	{ name: "Health and Beauty Spas" },
	{ name: "Miscellaneous Personal Services" },
	{ name: "Advertising Services" },
	{ name: "Consumer Credit Reporting Agencies" },
	{ name: "Commercial Photography, Art, and Graphics" },
	{ name: "Quick Copy, Reproduction, and Blueprinting Services" },
	{ name: "Stenographic and Secretarial Support" },
	{ name: "Exterminating and Disinfecting Services" },
	{ name: "Cleaning, Maintenance, and Janitorial Services" },
	{ name: "Employment Agencies and Temporary Help Services" },
	{ name: "Computer Programming, Data Processing, and Integrated Systems Design Services" },
	{ name: "Information Retrieval Services" },
	{ name: "Computer Maintenance, Repair and Services" },
	{ name: "Management, Consulting, and Public Relations Services" },
	{ name: "Detective Agencies, Protective Services, and Security Services, including Armored Cars, and Guard Dogs" },
	{ name: "Equipment, Tool, Furniture, and Appliance Rental and Leasing" },
	{ name: "Photofinishing Laboratories and Photo Developing" },
	{ name: "Business services" },
	{ name: "Car hire agency" },
	{ name: "Truck and Utility Trailer Rentals" },
	{ name: "Motor Home and Recreational Vehicle Rentals" },
	{ name: "Car Parks, Parking Meters and Garages" },
	{ name: "Automotive Body Repair Shops" },
	{ name: "Tyre Retreading and Repair Shops" },
	{ name: "Automotive Paint Shops" },
	{ name: "Automotive Service Shops (Non-Dealer)" },
	{ name: "Car Washes" },
	{ name: "Towing Services" },
	{ name: "Electronics Repair Shops" },
	{ name: "Air Conditioning and Refrigeration Repair Shops" },
	{ name: "Electrical and Small Appliance Repair Shops" },
	{ name: "Watch, Clock and Jewellery Repair" },
	{ name: "Furniture-Reupholstery, Repair, and Refinishing" },
	{ name: "Welding Services" },
	{ name: "Miscellaneous Repair Shops and Related Services" },
	{ name: "Motion Picture and Video Tape Production and Distribution" },
	{ name: "Motion Picture Theatres" },
	{ name: "Dance Halls, Studios and Schools" },
	{ name: "Theatrical Producers (Except Motion Pictures) and Ticket Agencies" },
	{ name: "Bands, Orchestras, and Miscellaneous Entertainers" },
	{ name: "Billiard and Pool Establishments" },
	{ name: "Bowling Alleys" },
	{ name: "Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters" },
	{ name: "Tourist Attractions and Exhibits" },
	{ name: "Public Golf Courses" },
	{ name: "Video Amusement Game Supplies" },
	{ name: "Video Game Arcades/Establishments" },
	{ name: "Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Race Tracks" },
	{ name: "Amusement parks, circuses, carnivals and fortune tellers" },
	{ name: "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses" },
	{ name: "Aquariums, Seaquariums, Dolphinariums, and Zoos" },
	{ name: "Recreation Services" },
	{ name: "Dentists and Orthodontists" },
	{ name: "Opticians, Optical Goods, and Eyeglasses" },
	{ name: "Medical Services and Health Practitioners" },
	{ name: "Legal Services and Lawyers" },
	{ name: "Elementary and Secondary Schools" },
	{ name: "Colleges, Universities, Professional Schools, and Junior Colleges" },
	{ name: "Correspondence Schools" },
	{ name: "Business and Secretarial Schools" },
	{ name: "Vocational and Trade Schools" },
	{ name: "Schools and Educational Services" },
	{ name: "Child Care Services" },
	{ name: "Charitable and Social Service Organisations" },
	{ name: "Civic, Social, and Fraternal Associations" },
	{ name: "Political Organisations" },
	{ name: "Religious Organisations" },
	{ name: "Car Associations" },
	{ name: "Membership Organisations" },
	{ name: "Architectural, Engineering, and Surveying Services" },
	{ name: "Accounting, Auditing, and Bookkeeping Services" },
	{ name: "Professional Services" },
	{ name: "Government Services" },
	{ name: "Veterinary Services" },
	{ name: "Agricultural Co-operatives" },
	{ name: "Landscaping and Horticultural Services" }
];
export const INCOME_12 = [
	{ name: "Too early to say" },
	{ name: "$0.00 - $20,000.00" },
	{ name: "$20,000.00 - $150,000.00" },
	{ name: "$150,000.00 - $500,000.00" },
	{ name: "$500,000.00 - $1,000,000.00" },
	{ name: "$1,000,000.00 & above" },

];