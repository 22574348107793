const identityVerification = {
	getDetail: '/kyc/kycs/detail',
	uploadFile: '/kyc/kycs/multiple-upload',
	submit: '/kyc/kycs/submit',
	getToken: '/kyc/authentications/generate-token',
	getProfile: '/kyc/authentications/profile',
    putEscrow: '/kyc/kycs/margin',
};

export default identityVerification;
