import { StepIdentityType } from '../../types';

export const StepIdentityKyb: StepIdentityType[] = [
	{
		title: 'kyc-form.step1.title',
		key: ['FirstName', 'Middle', 'LastName', 'DateOfBirth', 'Address', 'City', 'State', 'ZipCode', 'Country'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step2.title',
		key: ['DocumentType', 'IdentityCardFront', 'IdentityCardBack', 'SsnNumber', 'SsnFront', 'SsnBack'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step3.title',
		key: [
			'BusinessName',
			'BusinessType',
			'BusinessAddress',
			'BusinessCity',
			'BusinessState',
			'BusinessZipCode',
			'BusinessCountry',
			'PhoneNumber',
			'Email',
		],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step4.title',
		key: ['BankName', 'BankAddress', 'BankAccount', 'BankConfirmAccount', 'BankRoutingNumber', 'BankSwiftCode'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step5.title',
		key: [
			'CompanyLogo',
			'IrsLetter',
			'BusinessLicense',
			'VoidCheckBusinessFront',
			'VoidCheckBusinessBack',
			'Website',
			'Description',
		],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'location',
		key: ['Longitude', 'Latitude'],
		items: [],
		hasItemInvalid: false,
	},
];

export const StepIdentityKyc: StepIdentityType[] = [
	{
		title: 'form.kyc.step1.title',
		key: [
			'FirstName',
			'Middle',
			'LastName',
			'DateOfBirth',
			'Address',
			'City',
			'State',
			'ZipCode',
			'Country',
			'UtilityBillType',
			'UtilityBillFront',
			'Email',
			'PhoneNumber'
		],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'form.kyc.step2.title',
		key: ['DocumentType', 'IdentityCardFront', 'IdentityCardBack', 'SsnNumber', 'SsnFront', 'Selfie'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'form.kyc.step3.title',
		key: [
			'BankName',
			'BankAddress',
			'BankAccount',
			'BankConfirmAccount',
			'BankRoutingNumber',
			'BankSwiftCode',
			'VoidCheckPersonalFront',
			'VoidCheckPersonalBack',
		],
		items: [],
		hasItemInvalid: false,
	},
];
