import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfile } from '../types';
import identityVertificationEndpoint from '../utils/endpoints/identity-vertification.endpoint';

@Injectable({ providedIn: 'root' })
export class UserService {
	#httpClient = inject(HttpClient);

	get = (): Observable<UserProfile> => this.#httpClient.get<UserProfile>(identityVertificationEndpoint.getProfile);
}
