import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Signal, signal } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { catchError, EMPTY, Observable, of, switchMap, tap, throwError } from 'rxjs';
import { IdentityVerificationService } from '../services';
import { PersonalProfile, TokenType } from '../types';
import { IdentityType } from '../enums';
import { storage } from '../utils';
import { countries } from '../../mock-api/apps/contacts/data';

@Injectable({ providedIn: 'root' })
export class AuthService {
	#httpClient = inject(HttpClient);
	identityVerificationService = inject(IdentityVerificationService);

	private readonly _authenticated = signal<boolean>(!!this.accessToken);

	get isAuthenticated(): Signal<boolean> {
		return this._authenticated.asReadonly();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for access token
	 */
	set accessToken(token: string) {
		localStorage.setItem('accessToken', token);
	}

	get accessToken(): string {
		return localStorage.getItem('accessToken') ?? '';
	}

	/**
	 * Setter & getter for access token
	 */
	set clientId(token: string) {
		localStorage.setItem('clientId', token);
	}

	get clientId(): string {
		return localStorage.getItem('clientId') ?? '';
	}

	set personalProfile(profile: PersonalProfile) {
		if (!profile) return;
		localStorage.setItem('personalProfile', JSON.stringify(profile));
	}

	get personalProfile(): PersonalProfile | null {
		const strProfile = localStorage.getItem('personalProfile');
		if (!strProfile) return null;
		return JSON.parse(strProfile);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Forgot password
	 *
	 * @param email
	 */
	// forgotPassword(email: string): Observable<any> {
	// 	return this._httpClient.post('api/auth/forgot-password', email);
	// }

	/**
	 * Reset password
	 *
	 * @param password
	 */
	// resetPassword(password: string): Observable<any> {
	// 	return this._httpClient.post('api/auth/reset-password', password);
	// }

	/**
	 * Sign in
	 *
	 * @param credentials
	 */
	// signIn(credentials: { email: string; password: string }): Observable<any> {
	// 	// Throw error, if the user is already logged in
	// 	if (this.isAuthenticated()) {
	// 		return throwError('User is already logged in.');
	// 	}
	//
	// 	return this._httpClient.post('/kyc/authentications/sign-in', credentials).pipe(
	// 		switchMap((response: any) => {
	// 			// Store the access token in the local storage
	// 			this.accessToken = response.accessToken;
	//
	// 			// Set the authenticated flag to true
	// 			this._authenticated.set(true);
	// 			this._userService.get().subscribe();
	//
	// 			// Store the user on the user service
	// 			// this._userService.user = response.user;
	//
	// 			// Return a new observable with the response
	// 			return of(response);
	// 		}),
	// 	);
	// }

	/**
	 * Sign in using code
	 *
	 * @param credentials
	 */
	signInUsingCode = (credentials): Observable<TokenType> =>
		this.identityVerificationService.getToken(credentials).pipe(
			switchMap((response: TokenType) => {
				this.accessToken = response.accessToken;
				this.clientId = response.clientId;
				let personalProfile = response.personalProfile
				if (personalProfile?.country) {
					personalProfile.country = countries.find((x) => x.iso === personalProfile?.country?.toLocaleLowerCase())?.name;
				}
				this.personalProfile = personalProfile;
				storage.setItem('kycType', response.kycType);
				this._authenticated.set(true);
				return of(response);
			}),
		);
	// /refresh-token
	/**
	 * Sign in using the access token
	 */
	signInUsingToken(): Observable<any> {
		// Sign in using the token
		return this.#httpClient.get('/kyc/authentications/profile').pipe(
			catchError(() =>
				// Return false
				of(false),
			),
			switchMap((response: any) => {
				// Replace the access token with the new one if it's available on
				// the response object.
				//
				// This is an added optional step for better security. Once you sign
				// in using the token, you should generate a new one on the server
				// side and attach it to the response object. Then the following
				// piece of code can replace the token with the refreshed one.
				if (response.accessToken) {
					this.accessToken = response.accessToken;
				}

				// Set the authenticated flag to true
				this._authenticated.set(true);

				// // Store the user on the user service
				// this._userService.user = response.user;

				// Return true
				return of(true);
			}),
		);
	}

	/**
	 * Sign out
	 */
	signOut(): Observable<any> {
		// Remove the access token from the local storage
		localStorage.removeItem('accessToken');
		localStorage.removeItem('clientId');

		// Set the authenticated flag to false
		this._authenticated.set(false);

		// Return the observable
		return of(true);
	}

	/**
	 * Sign up
	 *
	 * @param user
	 */
	// signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
	// 	return this.#httpClient.post('api/auth/sign-up', user);
	// }

	/**
	 * Unlock session
	 *
	 * @param credentials
	 */
	// unlockSession(credentials: { email: string; password: string }): Observable<any> {
	// 	return this.#httpClient.post('api/auth/unlock-session', credentials);
	// }

	/**
	 * Check the authentication status
	 */
	check(): Observable<boolean> {
		// Check if the user is logged in
		if (this.isAuthenticated()) return of(true);

		// Check the access token availability
		if (!this.accessToken) return of(false);

		// Check the access token expire date
		if (AuthUtils.isTokenExpired(this.accessToken)) return of(false);

		// If the access token exists, and it didn't expire, sign in using it
		return this.signInUsingToken();
	}
}
